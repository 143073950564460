import React, {useState, useEffect} from "react"

import { graphql } from "gatsby"

import Link from "../components/Link";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import RightContainer from "../components/RightContainer";

import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Hidden from '@material-ui/core/Hidden';
import {MenuContext, StyledBox, StyledPrevNextLink, PostDate, PostTitle, PostContent} from '../components/PostUtils.js';
import MUIDrawer from "../components/Drawer";

import { ThemeProvider } from 'styled-components'
import theme from "../theme.js";


export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        ispage
        ispublished
        tags
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
  container: {
    flexGrow: 1,
  },
  grid: {
    height: "100vh",
  },

  topgrid: {
    marginTop: "10px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ContainerDiv = styled.div`
  flex-grow: 1;
  padding-left: 35px;
  padding-right: 35px;
  min-height: 100vh;

  ${props => props.theme.breakpoints.between("sm", "xl")} {
    background: 
      linear-gradient(#4c494f, #4c494f) bottom left/ 50% 25px,
      linear-gradient(#e1b5b3, #e1b5b3) top right / 50% 25px,
      linear-gradient(#4c494f, #4c494f) top left/ 25px 50%,
      linear-gradient(#e1b5b3, #e1b5b3) bottom right / 25px 50%, #fff;
     background-repeat: no-repeat;
  }

  ${props => props.theme.breakpoints.down("xs")} {
    background: 
      linear-gradient(#4c494f, #4c494f) bottom left/ 100% 25px, // grey, bottom 
      linear-gradient(#e1b5b3, #e1b5b3) top right / 100% 25px, // pink, top
      linear-gradient(#fff, #fff) top left/ 25px 100%, // grey, left
      linear-gradient(#fff, #fff) bottom right / 25px 100%, #fff; // pink, right

    background-repeat:no-repeat;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 1.7em;
  }

`;

const PostContainer = styled.div`
  /*background-color: lightyellow;*/
  min-height: 50%;
  min-width: 50%;
  max-width: 75%;
  margin-right: 10em;
  margin-left: 2em;
  margin-top: 8em;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: right;
  /*border: 1px solid black;*/
  /*overflow: scroll;*/
  margin-bottom: 4em;

  ${props => props.theme.breakpoints.down("xs")} {
    margin-top: 0rem;
    margin-right: 0rem;
    margin-left: 0rem;
    min-height: auto;
    min-width: auto;
    max-width: 100%;

  }
`;


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext
}) {

  const classes = useStyles();

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const next = pageContext.next ? {
    url: `${pageContext.next.frontmatter.slug}`,
    title: pageContext.next.frontmatter.title
  } : null;
  const prev = pageContext.previous ? {
    url: `${pageContext.previous.frontmatter.slug}`,
    title: pageContext.previous.frontmatter.title
  } : null;

  // Keep the burger menu closed when it first starts
  const [isOpen, setIsOpen] = useState(false);

  
  return (
    <Layout>
      <MenuContext.Provider value={{isOpen: isOpen, setIsOpen: setIsOpen}}>
        <Hidden smUp>
          <MUIDrawer/>
        </Hidden>
        <Seo
          title={frontmatter.title}
          description={frontmatter.description || 'No description provided by post'}
    /*image={post.frontmatter.image.childImageSharp.sizes.src}*/
          pathname={frontmatter.slug}
          article
        />
        <ThemeProvider theme={theme}>
          <ContainerDiv>
            <Grid container spacing={0}>
              <Hidden smUp>
                <Grid item xs={12}>
                  <RightContainer/>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <PostContainer>
                  <PostDate>{frontmatter.date}</PostDate>
                  <PostTitle>{frontmatter.title}</PostTitle>
                  <PostContent
                    dangerouslySetInnerHTML={{ __html: html }}>
                  </PostContent>
                  {!frontmatter.ispage && (
                    <StyledBox>
                      {!next && (
                        <StyledPrevNextLink>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                          }}>
                            <span></span>
                          </div>
                        </StyledPrevNextLink>
                      )}
                      
                      {next && (
                        <StyledPrevNextLink to={next.url}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            
                          }}>
                            <ChevronLeftIcon/>
                              <span>Previous post</span>
                          </div>
                        </StyledPrevNextLink>
                      )}
                      {prev && (
                        <StyledPrevNextLink to={prev.url}>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            
                          }}>
                              <span>Next post</span>
                            <ChevronRightIcon/>
                          </div>
                        </StyledPrevNextLink>
                      )}
                      
                    </StyledBox>
                  )}
                  
                </PostContainer>
              </Grid>
              <Hidden xsDown>
                <Grid item sm={2} md={2} lg={2} xl={2}>
                  <RightContainer/>
                </Grid>
              </Hidden>
            </Grid>
          </ContainerDiv>
        </ThemeProvider>
      </MenuContext.Provider>   
    </Layout>
  );
};

